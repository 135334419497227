export enum Links {
  HOME = '/',
  REQUEST = '/request-a-demo',
  SIGN = '/sign-in',
}

export enum ScrollLinks {
  WHAT_IS_CODEPLATFORM = 'what-is-codeplatform',
  WHY_CODEPLATFROM = 'why-codeplatform',
  HOW_CODEPLATFORM_WORKS = 'how-codeplatform-works',
  WHO_IS_CODEPLATFORM_FOR = 'who-is codeplatform-for',
}

export enum LinkedInSocial {
  SERGEY_SHILO = 'https://www.linkedin.com/in/sergey-shilo/',
  VASILI_VEKA = 'https://www.linkedin.com/in/veka-vasili/',
  DMITRY_SUVOROV = 'https://www.linkedin.com/in/mrsuvorov/',
  MIKHAIL_PAKHOMAU = 'https://www.linkedin.com/in/mikhail-pakhomau-3a8baa75/',
}

export enum SocialLinks {
  TWITTER = 'https://twitter.com/SkyBreakersio',
  DISCORD = 'https://discord.gg/q43Yg7qEEF',
  TELEGRAM = 'https://t.me/skybreakersio',
  FACEBOOK = 'https://www.facebook.com/SkyBreakers.io/',
  INSTAGRAM = 'https://www.instagram.com/skybreakers.io',
  LINE = 'https://lin.ee/VzTjVhz',
  SNAPCHAT = 'https://www.snapchat.com/add/vasuasili',
  MEDIUM = 'https://medium.com/@SkyBreakers.io',
}

export enum ExternalLinks {
  WHITEPAPER = 'https://skybreakers.gitbook.io/skybreakers.io/litepaper',
  PRIVACY_POLICY = 'https://skybreakers.gitbook.io/skybreakers.io/legals/privacy-policy',
  TERMS_OF_CONDITIONS = 'https://skybreakers.gitbook.io/skybreakers.io/legals/terms-and-conditions',
  HOW_TO_BUY = 'https://skybreakers.gitbook.io/skybreakers.io/how-to-buy-nft',
  DOWNLOAD_APP_ANDROID = 'https://play.google.com/store/apps/details?id=com.altwolf.chaos3&fbclid=IwAR3BaQVyLnc1TcdrJnuv73wXAvc_nkI5XMnWAfKXBICofIeibl1Oen117UI',
  DOWNLOAD_APP_IOS = 'https://apps.apple.com/by/app/skybreakers-helicopters/id1525525181',
}

export const SOCIAL_USERS_COUNT_API =
  'https://skybreakers-get-social-media.vercel.app/social_media.json';

export const ORDER_KEY = 'current_order';
export const SUCCESS_URL_KEY = 'success_url_href';
export const DISCOUNT_KEY = 'discount';
