import { combineReducers, configureStore } from '@reduxjs/toolkit';
import productReducer from '@/Store/Reducers/productReducer';
import cartReducer from '@/Store/Reducers/cartReducer';
import orderReducer from '@/Store/Reducers/ordersReducer';
import socialReducer from '@/Store/Reducers/socialReducer';
import accountNftsReducer from '@/Store/Reducers/accountNftsReducer';
import accountTokensReducer from '@/Store/Reducers/accountTokensReducer';
import cryptoReducer from '@/Store/Reducers/cryptoReducer';

const rootReducer = combineReducers({
  productReducer,
  cartReducer,
  orderReducer,
  socialReducer,
  accountNftsReducer,
  accountTokensReducer,
  cryptoReducer,
});

export const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActionPaths: [
            'payload.provider',
            'payload.web3Provider',
            'payload.signer',
          ],
          ignoredPaths: [
            'cryptoReducer.provider',
            'cryptoReducer.web3Provider',
            'cryptoReducer.signer',
          ],
        },
      }),
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
