import React, { useEffect } from 'react';
import qs from 'query-string';
import { useAppDispatch } from '@/hooks/redux';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { fetchProductsAction } from '@/Store/actions/productActions/fetchProducts';
import { fetchSocialsAction } from '@/Store/actions/social/fetchSocial';
import { DISCOUNT_KEY } from '@/utils/constants';

const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
};

const Layout: React.FC<{ children: any; location: any }> = ({
  children,
  location,
}) => {
  const dispatch = useAppDispatch();
  const params = qs.parse(location.search);

  if (params.discount) {
    localStorage.setItem(DISCOUNT_KEY, params.discount as string);
  }

  useEffect(() => {
    dispatch(fetchProductsAction());
    dispatch(fetchSocialsAction());
  }, []);

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      {children}
    </AlertProvider>
  );
};

export default Layout;
