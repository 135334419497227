const React = require('react');
const { Provider } = require('react-redux');
const buffer = require('buffer');
const { setupStore } = require('./src/Store');

exports.wrapRootElement = ({ element }) => {
  global.Buffer = buffer.Buffer;
  return <Provider store={setupStore()}>{element}</Provider>;
};

exports.onClientEntry = () => {
  global.Buffer = buffer.Buffer;
};
