import { IOrder } from '@/types/order';
import { ORDER_KEY } from '@/utils/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IOrderReducer {
  order: IOrder;
  isLoading: boolean;
  error: string;
  currentOrderToken?: string;
  discountError?: string | null;
}
const isClient = typeof window !== 'undefined';
let currentOrderToken = null;

if (isClient) {
  currentOrderToken = localStorage.getItem(ORDER_KEY);
}

const initialState: IOrderReducer = {
  order: null,
  isLoading: false,
  error: null,
  currentOrderToken,
  discountError: null,
};

export const orderSlice = createSlice({
  initialState,
  name: 'orders',
  reducers: {
    addOrder(state, action: PayloadAction<IOrder>) {
      state.order = action.payload;
      state.isLoading = false;
      state.currentOrderToken = action.payload.order_token;
    },
    orderFetching(state) {
      state.isLoading = true;
    },
    orderFetchingSuccess(state, action: PayloadAction<IOrder>) {
      state.isLoading = false;
      state.order = action.payload;
    },
    orderFetchingError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    closeOrder(state) {
      state.order = null;
    },
    applyDiscountFetching(state) {
      state.discountError = null;
      state.isLoading = true;
    },
    applyDiscountSuccess(state) {
      state.isLoading = false;
    },
    applyDiscountError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.discountError = action.payload;
    },
  },
});

export default orderSlice.reducer;
