import { IAccountTokens } from '@/types/account';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  accountTokens: {},
  isFetching: false,
  error: null,
  isWalletConnected: false,
};

export const accountTokensSlice = createSlice({
  initialState,
  name: 'accountTokens',
  reducers: {
    accountFetching(state) {
      state.isFetching = true;
    },
    accountFetchingSuccess(state, action: PayloadAction<IAccountTokens>) {
      state.isFetching = false;
      state.accountTokens = action.payload || {};
    },
    accountFetchingError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isFetching = false;
    },
    setIsWalletConnected(state, action: PayloadAction<boolean>) {
      state.isWalletConnected = action.payload;
      if (!action.payload) {
        state.accountTokens = {};
      }
    },
  },
});

export default accountTokensSlice.reducer;
