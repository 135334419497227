import { IProduct } from '@/types/products';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const persistedProductsInCart =
  typeof window !== 'undefined' &&
  JSON.parse(window.localStorage.getItem('products'));
interface ICartState {
  productsInCart: IProduct[];
  count: number[];
  isCartOpen: boolean;
}

const initialState: ICartState = {
  productsInCart: persistedProductsInCart?.productsInCart || [],
  count: persistedProductsInCart?.count || [],
  isCartOpen: false,
};

export const cartSlice = createSlice({
  initialState,
  name: 'cart',
  reducers: {
    addProductToCart(state, action: PayloadAction<IProduct>) {
      const currentProducts = [...state.productsInCart];
      currentProducts.push({
        ...action.payload,
        count: action.payload.count || 1,
      });
      state.productsInCart = currentProducts;
    },
    setCount(state, action: PayloadAction<{ idx: number; value: number }>) {
      const productIndexById = state.productsInCart.findIndex(
        (product) => product.id === action.payload.idx,
      );

      state.productsInCart[productIndexById].count = action.payload.value || 1;
    },
    removeFromCart(state, action: PayloadAction<{ id: number }>) {
      state.productsInCart = state.productsInCart.filter(
        (product) => product.id !== action.payload.id,
      );
    },
    toggleCart(state, action: PayloadAction<{ isOpen: boolean }>) {
      state.isCartOpen = action.payload.isOpen;
    },
  },
});

export default cartSlice.reducer;
