import { AppDispatch } from '@/Store';
import { productSlice } from '@/Store/Reducers/productReducer';
import { fetchProducts } from '@/utils/api';

export const fetchProductsAction = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(productSlice.actions.productFetching());
    const products = await fetchProducts();

    dispatch(productSlice.actions.productFetchingSuccess(products.data));
  } catch (e) {
    productSlice.actions.productFetchingError(e.toString());
  }
};
