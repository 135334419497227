import { IProduct } from '@/types/products';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IProductState {
  products: IProduct[];
  isFetching: boolean;
  error: string;
}

const initialState: IProductState = {
  products: [],
  isFetching: true,
  error: '',
};

export const productSlice = createSlice({
  initialState,
  name: 'product',
  reducers: {
    productFetching(state) {
      state.isFetching = true;
    },
    productFetchingSuccess(state, action: PayloadAction<IProduct[]>) {
      state.isFetching = false;
      state.products = action.payload;
    },
    productFetchingError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

export default productSlice.reducer;
