/* eslint-disable */
const apiUrl =
  process.env.NODE_ENV === 'production' && process.env.GATSBY_ISPROD === 'TRUE'
    ? process.env.GATSBY_PROD_API
    : process.env.GATSBY_DEV_API;

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export async function createOrder(data = {}) {
  const response = await fetch(`${apiUrl}/orders`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers,
  });
  return await response.json();
}

export async function assignDaapWallet(order_token) {
  const response = await fetch(`${apiUrl}/orders/${order_token}/wallet/dapp`, {
    method: 'PATCH',
    headers,
  });
  return await response.json();
}

export async function assignDirectWallet(order_token) {
  const response = await fetch(
    `${apiUrl}/orders/${order_token}/wallet/direct`,
    {
      method: 'PATCH',
      headers,
    },
  );
  return await response.json();
}

export async function subscribe(email = '') {
  const response = await fetch(`${apiUrl}/subscribers`, {
    method: 'POST',
    body: JSON.stringify({ email }),
    headers,
  });
  return await response.json();
}

export async function fetchProducts() {
  const response = await fetch(`${apiUrl}/products`, {
    method: 'GET',
    headers,
  });
  return await response.json();
}

export async function fetchAccountNfts(walletAddress, perPage, pageNumber) {
  const response = await fetch(
    `${apiUrl}/account/${walletAddress}/1?per_page=` +
      perPage +
      `&page=` +
      pageNumber,
    {
      method: 'GET',
      headers,
    },
  );
  return await response.json();
}

export async function fetchAccountTokens(walletAddress) {
  const response = await fetch(
    `${apiUrl}/account/${walletAddress}/erc20_presale/quantity`,
    {
      method: 'GET',
      headers,
    },
  );
  return await response.json();
}

export async function fetchOrder(orderToken) {
  const response = await fetch(`${apiUrl}/orders/${orderToken}`, {
    method: 'GET',
    headers,
  });
  return await response.json();
}
