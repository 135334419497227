import { AppDispatch } from '@/Store';
import { socialSlice } from '@/Store/Reducers/socialReducer';
import { SOCIAL_USERS_COUNT_API } from '@/utils/constants';

export const fetchSocialsAction = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(socialSlice.actions.socialFetching());
    const response = await fetch(SOCIAL_USERS_COUNT_API);
    const socials = await response.json();
    dispatch(socialSlice.actions.socialFetchingSuccess(socials));
  } catch (e) {
    socialSlice.actions.socialFetchingError(e.toString());
  }
};
