import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICryptoReducer, IWeb3Modal } from '@/types/crypto';

const initialState: ICryptoReducer = {
  provider: null,
  web3Provider: null,
  address: null,
  chainId: null,
  signer: null,
  network: null,
};

export const cryptoSlice = createSlice({
  initialState,
  name: 'crypto',
  reducers: {
    setWeb3Prodvider(state, action: PayloadAction<IWeb3Modal>) {
      state.provider = action.payload.provider;
      state.web3Provider = action.payload.web3Provider;
      state.address = action.payload.address;
      state.signer = action.payload.signer;
      state.network = action.payload.network;
      state.chainId = action.payload.chainId;
    },
    setAddress(state, action: PayloadAction<{ address: string }>) {
      state.address = action.payload.address || null;
    },
    setChainId(state, action: PayloadAction<{ chainId: number }>) {
      state.chainId = action.payload.chainId || null;
    },
    resetWeb3Provider() {
      return initialState;
    },
  },
});

export default cryptoSlice.reducer;
