import { ISocialInfo } from '@/types/social';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IProductState {
  socialInfo: ISocialInfo;
  isFetching: boolean;
  error: string;
}

const initialState: IProductState = {
  socialInfo: {
    discord: 0,
    telegram: 0,
    twitter: 0,
    facebook: 0,
  },
  isFetching: false,
  error: '',
};

export const socialSlice = createSlice({
  initialState,
  name: 'social',
  reducers: {
    socialFetching(state) {
      state.isFetching = true;
    },
    socialFetchingSuccess(state, action: PayloadAction<ISocialInfo>) {
      state.isFetching = false;
      state.socialInfo = action.payload;
    },
    socialFetchingError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

export default socialSlice.reducer;
