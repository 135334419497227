import { IAccountProducts } from '@/types/account';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  account: {},
  isFetching: false,
  currentPage: 1,
  perPage: 10,
  pagesCount: 1,
  error: null,
  isWalletConnected: false,
};

export const accountNftSlice = createSlice({
  initialState,
  name: 'account',
  reducers: {
    accountFetching(state) {
      state.isFetching = true;
    },
    accountFetchingSuccess(state, action: PayloadAction<IAccountProducts>) {
      state.isFetching = false;
      state.account = action.payload || {};
      state.pagesCount = action.payload.meta.last_page || 1;
    },
    accountFetchingError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isFetching = false;
    },
    setCurrentNftsPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setIsWalletConnected(state, action: PayloadAction<boolean>) {
      state.isWalletConnected = action.payload;
      if (!action.payload) {
        state.account = {};
      }
    },
  },
});

export default accountNftSlice.reducer;
